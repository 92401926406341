import React, { useState } from "react"
import styled from "styled-components"
import Modal from "react-modal"
import Work from "./work"
import { breakpoint } from "styled-components-breakpoint"
import Link from "../layout/link"
import LoadingImage from "../LoadingImage";

Modal.setAppElement("#___gatsby")

const Square = styled.li`
  width: 50%;
  position: relative;
  transition: all 0.2s;

  :after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  :hover {
    filter: blur(4px);
    cursor: pointer;
  }

  ${breakpoint(`desktop`)`
    width: 33%;
  `}
`

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
`

const Content = styled.div`
  height: 100%;
  position: relative;
`
const absoluteImageStyle = {
  position: `absolute`,
  left: 0,
  top: 0,
  width: `100%`,
  height: `100%`,
}

export default function WorkListItem({ work }) {
  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      {work.data.image && work.data.image.url && (
        <>
          <Square key={work.id}>
            <Link to={work.url}>
              <Container>
                <Content>
                  <LoadingImage src={work.data.image.url} alt={work.data.image.alt} style={{...absoluteImageStyle, objectFit: 'contain'}} />
                </Content>
              </Container>
            </Link>
          </Square>
        </>
      )}
      {work.data.presentation_image && work.data.presentation_image.url && (
        <>
          <Square key={work.id} onClick={openModal}>
            <Container>
              <Content>
                <LoadingImage src={work.data.presentation_image.url} alt={work.data.presentation_image.alt} style={absoluteImageStyle} />
              </Content>
            </Container>
          </Square>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
          >
            <Work work={work} closeModal={closeModal} />
          </Modal>
        </>
      )}
    </>
  )
}
