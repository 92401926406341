import React, {useState} from 'react';
import styled from 'styled-components';
import {useThemeContext} from "./provider/theme";
import Oval from "./Oval";

const Container = styled.div`
  display: block;
  position: relative;
  border-width: 1px;
  border-style: solid;
  transition: border-color 0.3s ease;
  border-color: ${props => props.$loaded ? 'transparent' : 'rgb(204, 204, 204)'};
  border-radius: 5px;
  min-height: 200px;
`;

const Img = styled.img`
  opacity: ${props => props.$loaded ? 1 : 0};
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  object-fit: contain;
`

const Loading = styled.div`
  opacity: ${props => props.$loaded ? 0 : 1};
  width: 100%;
  transition: opacity 0.3s ease;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
`

const LoadingImage = ({style = {}, src = '', alt = '', className = ''}) => {
    const [loaded, setLoaded] = useState(false)
    const theme = useThemeContext()

    const onLoad = () => {
        setLoaded(true)
    }

    return (
        <Container style={style} className={className} $loaded={loaded}>
            <Img src={src} alt={alt} $loaded={loaded} onLoad={onLoad}/>
            <Loading $loaded={loaded}>
                <Oval color={theme.color.black} secondaryColor="rgb(204, 204, 204)" height={40} width={40} />
            </Loading>
        </Container>
    );
};

export default LoadingImage
