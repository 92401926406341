import React from "react"
import TContrib from "../layout/multiLanguage/translateContrib"
import styled, { css } from "styled-components"
import { color } from "../provider/theme"
import "pure-react-carousel/dist/react-carousel.es.css"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import { breakpoint } from "styled-components-breakpoint"
import { languages } from "../../../config/config"
import { usePageContext } from "../provider/pageContext"
import LoadingImage from "../LoadingImage";

const Close = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;

  :before,
  :after {
    width: 100%;
    height: 10%;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: ${color(`black`)};
    transform-origin: center center;
    transition: transform 0.2s;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  :after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  :hover {
    cursor: pointer;
    :before {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }
    :after {
      transform: translateX(-50%) translateY(-50%) rotate(-135deg);
    }
  }
`

const Container = styled.div`
  height: 100%;
  position: relative;
  margin: auto;
  max-width: 1400px;
  display: flex;
  flex-direction: column;

  ${breakpoint(`desktop`)`
    flex-direction: row;
  `}

  .carousel__slide-focus-ring {
    display: none;
  }
`

const ImgContainer = styled.div`
  flex: 2;
  display: flex;
  position: relative;
`

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledCarouselProvider = styled(CarouselProvider)`
  width: 100%;
  display: flex;
`

const StyledSlider = styled(Slider)`
  flex: 1;
  height: 100%;

  > div {
    height: 100%;
  }

  .wrapper,
  ul {
    height: 100%;
    display: flex;
  }
`

const StyledSlide = styled(Slide)`
  flex: 1;
  height: 100% !important;
`

const absoluteImageStyle = {
  position: `absolute`,
  left: 0,
  top: 0,
  width: `100%`,
  height: `100%`,
  objectFit: `contain`,
  minHeight: `200px`,
}

const ButtonCss = css`
  border: 0;
  position: relative;
  width: 50px;
  background: none;

  :before {
    content: "";
    width: 50px;
    height: 5px;
    background-color: ${color(`black`)};
    position: absolute;
    left: 5px;
    transform: rotate(-68deg);
    transform-origin: left bottom;
    top: 50%;
  }

  :after {
    content: "";
    width: 50px;
    height: 5px;
    background-color: ${color(`black`)};
    position: absolute;
    left: 5px;
    transform: rotate(68deg);
    transform-origin: left top;
    top: 50%;
  }

  :focus {
    outline: 0;
  }

  :disabled {
    opacity: 0.5;
  }
`

const StyledButtonBack = styled(ButtonBack)`
  ${ButtonCss};
  display: ${props => (props.$hide ? `none` : `block`)};
`

const StyledButtonNext = styled(ButtonNext)`
  ${ButtonCss};
  transform: rotate(180deg);
  display: ${props => (props.Hide ? `none` : `block`)};
`

export default function Work({ work, closeModal }) {
  const { lang = languages.default } = usePageContext()
  const text = [`title`, `material`, `size`, `year`]
    .filter(field => work.data[field] || work.data[`${field}_${lang}`])
    .map(field => <TContrib data={work.data} field={field} />)

  const validImages = work.data.images.filter(img => img.image.url)
  return (
    <Container>
      <ImgContainer>
        {validImages.length > 0 && (
          <StyledCarouselProvider
            totalSlides={work.data.images.length}
            dragEnabled={false}
          >
            <StyledButtonBack $hide={work.data.images.length <= 0} />
            <StyledSlider
              style={{
                height: `100%`,
              }}
            >
              {validImages.map(({ image }, i) => (
                <StyledSlide index={i} key={i}>
                  <Zoom wrapStyle={{ position: `static` }}>
                    <LoadingImage src={image.url} alt={image.alt} style={{...absoluteImageStyle, objectFit: 'contain'}} />
                  </Zoom>
                </StyledSlide>
              ))}
            </StyledSlider>
            <StyledButtonNext $hide={work.data.images.length <= 0} />
          </StyledCarouselProvider>
        )}
        {validImages.length === 0 && (
          <Zoom wrapStyle={{ position: `static` }}>
            <LoadingImage src={work.data.presentation_image.url} alt={work.data.presentation_image.alt} style={{...absoluteImageStyle, objectFit: 'contain'}} />
          </Zoom>
        )}
      </ImgContainer>
      <TextContainer>
        {text
          .filter(e => e)
          .map((e, i, j) => (
            <React.Fragment key={i}>
              {e}
              {i + 1 < j.length && `. `}
            </React.Fragment>
          ))}
      </TextContainer>
      <Close onClick={closeModal} />
    </Container>
  )
}
