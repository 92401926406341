import React from "react"
import Layout from "../components/layout/layout"
import styled from "styled-components"
import { graphql } from "gatsby"
import WorkListItem from "../components/work/workListItem"

const Container = styled.div``

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
  justify-content: center;
`

export default function Category({ ...props }) {
  const {
    data: {
      allPrismicWork: { nodes: works },
      allPrismicCategory: { nodes: cat },
    },
  } = props

  return (
    <Layout title="Oeuvres" {...props}>
      <Container>
        <List>
          {cat.map(cat => (
            <WorkListItem key={cat.id} work={cat} />
          ))}
          {works.map(work => (
            <WorkListItem key={work.id} work={work} />
          ))}
        </List>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProductById($id: String!) {
    allPrismicCategory(
      filter: {
        data: {
          categorie_parent: {
            id: {}
            document: { elemMatch: { id: { eq: $id } } }
          }
        }
      }
    ) {
      nodes {
        id
        uid
        url
        data {
          image {
            url
            alt
          }
        }
      }
    }
    allPrismicWork(
      sort: { order: DESC, fields: data___year }
      filter: {
        data: { category: { document: { elemMatch: { id: { eq: $id } } } } }
      }
    ) {
      nodes {
        data {
          material_en
          material_fr
          size
          title_en
          title_fr
          year
          presentation_image {
            url
            alt
          }
          images {
            image {
              url
              alt
            }
          }
        }
        id
      }
    }
  }
`
